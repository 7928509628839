/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import {useAuth} from '../../contexts/AuthContext';
import {useHistory} from 'react-router-dom';
import firebase from 'firebase/app';
import {ConsentForm} from '../forms/ConsentForm';
import {updateToLatestConsent} from '../../services/firestore';

export default function UpdateConsent() {
  const {currentUser, logout} = useAuth();
  const history = useHistory();

  async function updateConsent() {
    try {
      await updateToLatestConsent();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
      }}>
      <Container
        maxWidth="sm"
        sx={{
          alignItems: 'center',
          alignSelf: 'flex-start',
          mt: 2,
        }}>
        <Card
          sx={{
            display: 'flex',
            minHeight: 280,
          }}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              px: 2,
              pt: 1,
              alignItems: 'center',
              flex: 1,
            }}>
            <Box sx={{width: '100%', flex: 1}}>
              <ConsentForm updateConsent={updateConsent} />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}
