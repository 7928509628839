/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {useFormik} from 'formik';
import * as yup from 'yup';
import 'yup-phone';
import Autocomplete from '@material-ui/core/Autocomplete';
import {useAuth} from '../../contexts/AuthContext';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import {useHistory, useParams} from 'react-router-dom';

export default function SearchFacilites() {
  const {facilities, setStoredFacilityId, storedFacility} = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (storedFacility) {
      console.log('REDIRECTING');
      history.push('/');
    }
    return () => {};
  }, [storedFacility]);

  return (
    // <FirebaseAuthProvider firebase={firebase} {...config}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '90vh',
      }}>
      <Container
        maxWidth="sm"
        sx={{
          alignItems: 'center',
          py: 6,
          display: 'flex',
          flexDirection: 'column',
          px: 3,
          justifyContent: 'center',
        }}>
        <Typography align="center">
          Skanna av QR-koden med din mobilkamera
        </Typography>
      </Container>
      <Box
        sx={{
          alignSelf: 'center',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '90%',
        }}>
        <Box
          sx={{
            flex: 1,
            borderBottom: '2px solid black',
          }}></Box>
        <Typography sx={{px: 1}}>Eller</Typography>
        <Box
          sx={{
            flex: 1,
            borderBottom: '2px solid black',
          }}></Box>
      </Box>

      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          py: 6,
        }}>
        <Typography sx={{px: 1, alignSelf: 'center'}}>
          Sök på nattklubbsnamn
        </Typography>

        <Autocomplete
          freeSolo
          onChange={(event, newValue) => {
            console.log(newValue);

            const found = facilities?.find(
              (element) => element.name === newValue,
            );
            if (found) {
              setStoredFacilityId(found.facilityId);
            }
          }}
          options={facilities?.map((option) => option.name)}
          // InputProps={{
          //   startAdornment: (
          //     <InputAdornment position="start">
          //       <SearchIcon />
          //     </InputAdornment>
          //   ),
          // }}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              placeholder="Nattklubbsnamn"
              InputProps={{
                ...params.InputProps,
                autoComplete: 'new-password',
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Container>
    </Box>
  );
}

function PhoneNumberForm({onSignInSubmit}) {
  const validationSchema = yup.object({
    phone: yup.string('Enter your phone number').phone('SE').required(),
  });

  const formik = useFormik({
    initialValues: {
      phone: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      onSignInSubmit(values.phone);
      // setCodeIsSent(true);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        id="phone"
        name="phone"
        label="Phone number"
        type="tel"
        value={formik.values.phone}
        onChange={formik.handleChange}
        error={formik.touched.phone && Boolean(formik.errors.phone)}
        helperText={formik.touched.phone && formik.errors.phone}
      />
      {/* <Button
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        id="sign-in-button">
        Submit
      </Button> */}
    </form>
  );
}
