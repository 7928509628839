/* eslint-disable react/prop-types */
import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import * as yup from 'yup';
import {useFormik} from 'formik';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

export function FullNameForm({onNext, userFields, setUserFields}) {
  const validationSchema = yup.object({
    firstName: yup
      .string('Ange förnamn')
      .min(2, 'Ange giltigt förnamn')
      .max(30, 'Ange giltigt förnamn')
      .required('Ange förnamn'),
    lastName: yup
      .string('Ange efternamn')
      .min(2, 'Ange giltigt efternamn')
      .max(30, 'Ange giltigt efternamn')
      .required('Ange efternamn'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: userFields.firstName,
      lastName: userFields.lastName,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      setUserFields((prevState) => ({
        ...prevState,
        firstName: values.firstName,
        lastName: values.lastName,
      }));
      // onSubmit(values.firstName, values.lastName);
      onNext();
      // setCodeIsSent(true);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{height: '100%'}}>
      <Box
        sx={{
          flexDirection: 'column',
          height: '100%',
          display: 'flex',
        }}>
        <Typography variant="h6">Slutför profil</Typography>

        <TextField
          fullWidth
          size="small"
          id="firstName"
          name="firstName"
          label="Förnamn"
          margin="normal"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={
            formik.touched.firstName && formik.errors.firstName
              ? formik.errors.firstName
              : ' '
          }
        />
        <TextField
          fullWidth
          size="small"
          id="lastName"
          name="lastName"
          label="Efternamn"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={
            formik.touched.lastName && formik.errors.lastName
              ? formik.errors.lastName
              : ' '
          }
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            flex: 1,
          }}>
          <Box
            sx={{
              display: 'flex',
              mt: 2,
            }}>
            <Button
              sx={{flex: 1}}
              color="primary"
              variant="contained"
              type="submit"
              id="sign-in-button">
              Fortsätt
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
}
