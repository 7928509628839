import './App.css';
import {AuthProvider} from './contexts/AuthContext';
import Appbar from './components/Appbar';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
} from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import PrivateParamRoute from './components/PrivateParamRoute';
import CompleteProfileRoute from './components/CompleteProfileRoute';
import UpdateConsentRoute from './components/UpdateConsentRoute';
import GuestRoute from './components/GuestRoute';
import Login from './components/pages/Login';
import Dashboard from './components/pages/Dashboard';
import SearchFacility from './components/pages/SearchFacility';
import Terms from './components/pages/Terms';
import Policy from './components/pages/Policy';
import CompleteProfile from './components/pages/CompleteProfile';
import UpdateConsent from './components/pages/UpdateConsent';
import {useEffect, useState} from 'react';

function App() {
  const location = useLocation();

  const [path, setPath] = useState(null);

  useEffect(() => {
    console.log('the location');
    console.log(location);

    let theParam = location.pathname;
    theParam = theParam.split('/facility/');

    console.log(theParam[0]);
    setPath(theParam[0]);
    return () => {};
  }, [location]);

  return (
    <>
      {/* {location.pathname === '/login' ? <Appbar /> : null} */}
      {path === '/login' ||
      path === '/updateConsent' ||
      path === '/completeProfile' ? null : (
        <Appbar />
      )}
      <AuthProvider>
        <Switch>
          <PrivateRoute exact path="/search" component={SearchFacility} />

          <PrivateParamRoute path="/facility/:id" component={Dashboard} />

          <CompleteProfileRoute
            path="/completeProfile"
            component={CompleteProfile}
          />
          <UpdateConsentRoute path="/updateConsent" component={UpdateConsent} />

          <Route path="/terms" component={Terms} />
          <Route path="/policy" component={Policy} />

          <GuestRoute path="/login/:id" component={Login} />
          <GuestRoute path="/login/" component={Login} />
          <GuestRoute path="/login" component={Login} />
          <PrivateRoute path="/" component={Dashboard} />
        </Switch>
      </AuthProvider>
    </>
  );
}

export default App;
