/* eslint-disable react/prop-types */
import React, {useState, Fragment} from 'react';
import {Router, Route, Link} from 'react-router-dom';
// import {createBrowserHistory} from 'history';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/styles';
import {auth} from '../firebase';
import {useHistory} from 'react-router-dom';
import {createTheme} from '@material-ui/core/styles';

const drawerWidth = 240;
// const history = createBrowserHistory();
const theme = createTheme();

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  toolbar: theme.mixins.toolbar,
  aboveDrawer: {
    zIndex: theme.zIndex.drawer + 1,
  },
});

const useStyles = makeStyles({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  // Loads information about the app bar, including app bar height
  toolbar: theme.mixins.toolbar,
});

const MyToolbar = ({classes, title, onMenuClick, isDrawerOpen}) => (
  <Fragment>
    <AppBar
      position="sticky"
      sx={{zIndex: (theme) => 1400, backgroundColor: 'black'}}
      elevation={0}>
      <Toolbar>
        <Typography variant="h6" color="inherit">
          EasyJacket
        </Typography>
        <Box
          sx={{
            justifyContent: 'flex-end',
            display: 'flex',
            width: '100%',
          }}></Box>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="Menu"
          onClick={onMenuClick}>
          {isDrawerOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </Toolbar>
    </AppBar>
  </Fragment>
);

const MyDrawer = ({classes, variant, open, onClose, onItemClick, logout}) => (
  <Drawer variant={variant} anchor="top" open={open} onClose={onClose}>
    <div className={classes.toolbar} />
    <List sx={{backgroundColor: 'black', color: 'white'}}>
      <ListItem
        button
        to="/"
        onClick={() => {
          console.log('clicked');
          onClose();
        }}>
        <ListItemText sx={{textAlign: 'center'}}>Min biljett</ListItemText>
      </ListItem>
      <ListItem
        button
        onClick={() => {
          console.log('clicked');
          onClose();
        }}>
        <ListItemText sx={{textAlign: 'center'}}>Mina köp</ListItemText>
      </ListItem>
      <ListItem
        button
        onClick={() => {
          console.log('clicked');
          onClose();
        }}>
        <ListItemText sx={{textAlign: 'center'}}>Hjälp</ListItemText>
      </ListItem>
      <ListItem
        button
        onClick={() => {
          auth.signOut();
          onClose();
        }}>
        <ListItemText sx={{textAlign: 'center'}}>Logga ut</ListItemText>
      </ListItem>
    </List>
  </Drawer>
);

function AppBarInteraction({variant}) {
  const [drawer, setDrawer] = useState(false);
  const [title, setTitle] = useState('Home');
  const history = useHistory();

  const toggleDrawer = () => {
    setDrawer(!drawer);
  };

  const onItemClick = (title) => () => {
    // setTitle(title);
    setDrawer(variant === 'temporary' ? false : drawer);
    setDrawer(!drawer);
    // history.push('/');
  };
  const classes = useStyles();

  return (
    <>
      <MyToolbar
        title={title}
        onMenuClick={toggleDrawer}
        isDrawerOpen={drawer}
      />
      <MyDrawer
        anchor="top"
        open={drawer}
        onClose={toggleDrawer}
        onItemClick={onItemClick}
        variant={variant}
        classes={classes}
      />
    </>
  );
}

export default AppBarInteraction;
