/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import {useAuth} from '../../contexts/AuthContext';
import {useHistory} from 'react-router-dom';

import firebase from 'firebase/app';
import {BirthDateForm} from '../forms/BirthDateForm';
import {FullNameForm} from '../forms/FullNameForm';
import {
  updateUserFields,
  updateToLatestConsent,
} from '../../services/firestore';

export default function CompleteProfile() {
  const {currentUser, logout} = useAuth();
  const history = useHistory();

  async function handleLogout() {
    // setError('');

    try {
      await firebase.app().auth().signOut();
    } catch {
      //   setError('Failed to log out');
    }
  }

  const [error, setError] = useState(null);

  const [userFields, setUserFields] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',
  });

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = async (dateOfBirth, gender) => {
    try {
      await updateToLatestConsent();

      await updateUserFields(
        dateOfBirth,
        userFields.firstName,
        userFields.lastName,
        gender,
        currentUser.phoneNumber,
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
      }}>
      <Container
        maxWidth="sm"
        sx={{
          alignItems: 'center',
          alignSelf: 'flex-start',
          mt: 2,
        }}>
        <Card
          sx={{
            display: 'flex',
            minHeight: 280,
          }}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              px: 2,
              pt: 1,
              alignItems: 'center',
              flex: 1,
            }}>
            <Box sx={{width: '100%', flex: 1}}>
              {activeStep === 0 && (
                <FullNameForm
                  onNext={handleNext}
                  userFields={userFields}
                  setUserFields={setUserFields}
                />
              )}
              {activeStep === 1 && (
                <BirthDateForm
                  onBack={handleBack}
                  onComplete={handleComplete}
                  userFields={userFields}
                  setUserFields={setUserFields}
                />
              )}
            </Box>
          </CardContent>
        </Card>
        <Box
          sx={{
            display: 'flex',
            mt: 10,
            justifyContent: 'center',
          }}>
          <Button onClick={handleLogout}>Sign out</Button>
        </Box>
      </Container>
    </Box>
  );
}
