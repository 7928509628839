import React, {useEffect, useState} from 'react';
import LoadingButton from '@material-ui/lab/LoadingButton';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import {useAuth} from '../../contexts/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import QRCode from 'react-qr-code';
import useInterval from '../../hooks/useInterval';
import {renewCode} from '../../services/functions';
import {Typography} from '@material-ui/core';
import {usePageVisibility} from 'react-page-visibility';
import PaymentOptionDialog from '../dialogs/PaymentOptionDialog';

export default function Dashboard() {
  const isVisible = usePageVisibility();

  const {
    currentUser,
    userData,
    currentFacility,
    setStoredFacilityId,
    storedFacility,
  } = useAuth();
  const history = useHistory();
  const [callDelay, setCallDelay] = useState(10000);
  const [isEntering, setIsEntering] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [code, setCode] = useState({value: ' ', timestamp: 0});
  const [callCount, setCallCount] = useState(0);
  const [ticketLoading, setTicketLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let {id} = useParams();

  useEffect(() => {
    if (id === undefined || id === null) {
      // check local storage
      const storedFacility = JSON.parse(localStorage.getItem('storedFacility'));
      if (storedFacility) {
        // if local storage exists and not expired
        if (Date.now() < storedFacility.timestamp + 43200000) {
          console.log('EXISTS');
          setStoredFacilityId(storedFacility.facilityId);
        } else {
          setStoredFacilityId('x');
        }
      } else {
        console.log('DOESNOTEXIST');
        setStoredFacilityId('x');
      }
    } else {
      // save to local storage
      console.log('EXISTS');
      setStoredFacilityId(id);
    }

    return () => {};
  }, [id]);

  useEffect(() => {
    if (storedFacility) {
      if (storedFacility.ticketPrice > 0) {
        console.log('SHOW MODAL');
        handleClickOpen();
      }
    }
    return () => {};
  }, [storedFacility]);

  useEffect(() => {
    if (storedFacility === false && currentFacility === null) {
      console.log('REDIRECTING');
      history.push('/search');
    }
    if (storedFacility && currentFacility) {
      setStoredFacilityId(currentFacility.facilityId);
    }

    return () => {};
  }, [storedFacility, currentFacility]);

  async function requestNewKey() {
    try {
      setTicketLoading(true);
      const theCode = await renewCode();
      setCode({value: theCode.data.tempId, timestamp: Date.now()});
      setTicketLoading(false);

      setCallDelay(10000);
    } catch (error) {
      setTicketLoading(false);
      console.log(error);
      setCode({value: '', timestamp: 0});
      setIsRunning(false);
    }
  }

  useInterval(
    () => {
      if (callCount < 15) {
        requestNewKey();
        setCallCount(callCount + 1);
      } else {
        setIsRunning(false);
        setCode({value: '', timestamp: 0});
      }
    },
    isRunning ? callDelay : null,
  );

  useEffect(() => {
    setIsEntering(true);
    return () => {};
  }, []);

  useEffect(() => {
    if (isVisible === true) {
      setIsEntering(true);
    } else {
      setIsRunning(false);
    }
    return () => {};
  }, [isVisible]);

  useEffect(() => {
    if (isEntering) {
      if (code.timestamp < Date.now() - 10000 || code.timestamp === 0) {
        setCallCount(1);
        setIsRunning(true);
        requestNewKey();
      } else {
        setCallDelay(10000 - (Date.now() - code.timestamp));
        setIsRunning(true);
      }
      setIsEntering(false);
    }
  }, [code, isEntering]);

  const onTicketPress = async () => {
    setIsEntering(true);
  };

  return (
    <Box height={'90vh'} sx={{display: 'flex', flexDirection: 'column'}}>
      <Box
        sx={{
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          display: 'flex',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
          }}>
          {currentFacility ? (
            <Typography>
              {currentFacility ? currentFacility.name : ' '}
            </Typography>
          ) : (
            <Typography>
              {storedFacility ? storedFacility.name : ' '}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
          }}>
          <Typography>
            {currentFacility ? 'Incheckad' : 'Visa upp din kod i garderoben'}
          </Typography>
        </Box>
      </Box>
      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'center',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'absolute',
            alignSelf: 'center',
            opacity: code?.value.length > 0 ? 1 : 0.5,
          }}>
          <QRCode
            value={code?.value.length > 0 ? code?.value : 'www.easyjacket.se'}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'absolute',
            alignSelf: 'center',
          }}>
          {code?.value.length > 0 ? null : (
            <LoadingButton
              variant="contained"
              loading={ticketLoading}
              onClick={onTicketPress}>
              Generera kod
            </LoadingButton>
          )}
        </Box>
      </Container>
      <Box
        sx={{
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-end',
          display: 'flex',
        }}></Box>
      <PaymentOptionDialog
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      />
    </Box>
  );
}
