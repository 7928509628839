import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Image from 'material-ui-image';
import SwishLogo from '../../assets/SwishLogo.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{enter: 500, exit: 500}}
    />
  );
});

export default function PaymentOptionDialog({
  open,
  handleClickOpen,
  handleClose,
}) {
  return (
    <Dialog
      fullWidth
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth={'sm'}
      //   onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle
        id="alert-dialog-slide-title"
        sx={{backgroundColor: '#222222', color: 'white'}}>
        {'Välj betalningsmetod'}
      </DialogTitle>
      <DialogContent sx={{pb: 4, backgroundColor: '#222222'}}>
        <Typography variant="h4" align="center" color="#0aff85">
          25kr
        </Typography>
        {/* <DialogContentText id="alert-dialog-slide-description">
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </DialogContentText> */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pt: 4,
          }}>
          <ButtonBase
            onClick={() => {
              handleClose();
              window.location =
                'swish://paymentrequest?token=c28a4061470f4af48973bd2a4642b4fa&callbackurl=merchant%253A%252F%252F';
            }}
            sx={{
              py: 1,
              px: 5,
              width: '70%',
              alignSelf: 'center',
              border: '1px solid black',
              borderRadius: 6,
              backgroundColor: 'white',
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}>
              <Image
                aspectRatio={3.02}
                width={'100%'}
                height="undefined"
                src={SwishLogo}
              />
            </Box>
          </ButtonBase>

          <Box
            sx={{
              alignSelf: 'center',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '90%',
              py: 4,
            }}>
            <Box
              sx={{
                flex: 1,
                borderBottom: '2px solid white',
              }}></Box>
            <Typography sx={{px: 1, color: 'white'}}>Eller</Typography>
            <Box
              sx={{
                flex: 1,
                borderBottom: '2px solid white',
              }}></Box>
          </Box>
          <Button sx={{color: '#90caf9', fontSize: 20}} onClick={handleClose}>
            Betala i kassan
          </Button>
        </Box>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose}>Disagree</Button>
        <Button onClick={handleClose}>Agree</Button>
      </DialogActions> */}
    </Dialog>
  );
}
