/* eslint-disable react/prop-types */
import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useAuth} from '../contexts/AuthContext';

export default function UpdateConsentRoute({component: Component, ...rest}) {
  const {currentUser, isNewUser, isConsentLatest} = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        //if currentUser AND userData OK
        if (currentUser && !isNewUser && !isConsentLatest) {
          return <Component {...props} />;
        }
        if (currentUser && !isNewUser && isConsentLatest) {
          return <Redirect to="/" />;
        }
        if (currentUser && isNewUser && isConsentLatest) {
          return <Redirect to="/completeProfile" />;
        }
        return <Redirect to="/login" />;
      }}></Route>
  );
}
