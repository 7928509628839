/* eslint-disable react/prop-types */
import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import * as yup from 'yup';
import {useFormik} from 'formik';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import {format, isValid} from 'date-fns';

export function BirthDateForm({onBack, onComplete, setUserFields}) {
  const validationSchema = yup.object({
    year: yup
      .number('Ej giltig')
      .min(1900, 'Ej giltig')
      .max(2021, 'Ej giltig')
      .required('Saknas'),
    month: yup
      .number('Ej giltig')
      .min(1, 'Ej giltig')
      .max(12, 'Ej giltig')
      .required('Saknas'),
    day: yup
      .number('Ej giltig')
      .min(1, 'Ej giltig')
      .max(31, 'Ej giltig')
      .required('Saknas'),
    gender: yup.string('Saknas').oneOf(['male', 'female', 'other']),
  });

  const formik = useFormik({
    initialValues: {
      year: '',
      month: '',
      day: '',
      gender: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const theDate = new Date(values.year, values.month - 1, values.day);
      if (isValid(theDate)) {
        const res = format(theDate, 'yyyy-MM-dd');
        const y = res.substring(0, 4);
        const m = res.substring(5, 7);
        const d = res.substring(8, 10);

        if (
          parseInt(y) === values.year &&
          parseInt(m) === values.month &&
          parseInt(d) === values.day
        ) {
          onComplete(res, values.gender);
        } else {
          console.log('fail');
        }
      } else {
        console.log('FAIL');
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{height: '100%'}}>
      <Box
        sx={{
          flexDirection: 'column',
          height: '100%',
          display: 'flex',
        }}>
        <Typography variant="h7">Födelsedatum</Typography>

        <Box sx={{flexDirection: 'row', display: 'flex'}}>
          <TextField
            sx={{flex: 4}}
            id="year"
            name="year"
            label="År"
            type="number"
            margin="normal"
            placeholder="ÅÅÅÅ"
            value={formik.values.year}
            onChange={formik.handleChange}
            error={formik.touched.year && Boolean(formik.errors.year)}
            helperText={
              formik.touched.year && formik.errors.year
                ? formik.errors.year
                : ' '
            }
          />
          <TextField
            sx={{flex: 3, mx: 1}}
            id="month"
            name="month"
            label="Månad"
            type="number"
            margin="normal"
            placeholder="MM"
            value={formik.values.month}
            onChange={formik.handleChange}
            error={formik.touched.month && Boolean(formik.errors.month)}
            helperText={
              formik.touched.month && formik.errors.month
                ? formik.errors.month
                : ' '
            }
          />
          <TextField
            sx={{flex: 3}}
            id="day"
            name="day"
            margin="normal"
            label="dag"
            type="number"
            placeholder="DD"
            value={formik.values.day}
            onChange={formik.handleChange}
            error={formik.touched.day && Boolean(formik.errors.day)}
            helperText={
              formik.touched.day && formik.errors.day ? formik.errors.day : ' '
            }
          />
        </Box>
        <FormControl
          component="fieldset"
          error={formik.touched.gender && Boolean(formik.errors.gender)}>
          <Typography variant="h7">Kön</Typography>

          <RadioGroup
            row
            aria-label="gender"
            name="gender"
            value={formik.values.gender}
            onChange={formik.handleChange}>
            <FormControlLabel value="male" control={<Radio />} label="Man" />
            <FormControlLabel
              value="female"
              control={<Radio />}
              label="Kvinna"
            />
            <FormControlLabel
              value="other"
              control={<Radio />}
              label="Övrigt"
            />
          </RadioGroup>
          {formik.touched.gender && Boolean(formik.errors.gender) ? (
            <FormHelperText>Ange kön</FormHelperText>
          ) : (
            <FormHelperText> </FormHelperText>
          )}
        </FormControl>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            flex: 1,
          }}>
          <Box sx={{display: 'flex', mt: 0}}>
            <Button
              sx={{flex: 1, mr: 2}}
              color="primary"
              variant="outlined"
              id="sign-in-button"
              onClick={() => onBack()}>
              Tillbaka
            </Button>
            <Button
              sx={{flex: 1}}
              color="primary"
              variant="contained"
              type="submit"
              id="sign-in-button">
              Spara
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
}
