import firebase from 'firebase/app';

export async function checkIfNewUser() {
  const userDoc = firebase
    .firestore()
    .collection('users')
    .doc(firebase.auth().currentUser.uid);

  const ref = await userDoc.get();
  if (!ref.exists) {
    return true;
  }
  if (
    ref.data().dateOfBirth === '' ||
    ref.data().dateOfBirth === undefined ||
    ref.data().firstName === '' ||
    ref.data().firstName === undefined ||
    ref.data().gender === '' ||
    ref.data().gender === undefined ||
    ref.data().lastName === '' ||
    ref.data().lastName === undefined ||
    ref.data().phone === '' ||
    ref.data().phone === undefined
  ) {
    return true;
  }
  return false;
}

export async function checkIfMissingUserData(userData) {
  if (userData) {
    if (
      userData.dateOfBirth === null ||
      userData.dateOfBirth === undefined ||
      userData.dateOfBirth === ''
    ) {
      return true;
    }
    if (
      userData.firstName === null ||
      userData.firstName === undefined ||
      userData.firstName === ''
    ) {
      return true;
    }
    if (
      userData.gender === null ||
      userData.gender === undefined ||
      userData.gender === ''
    ) {
      return true;
    }
    if (
      userData.lastName === null ||
      userData.lastName === undefined ||
      userData.lastName === ''
    ) {
      return true;
    }
    if (
      userData.phone === null ||
      userData.phone === undefined ||
      userData.phone === ''
    ) {
      return true;
    }
    return false;
  }
  return true;
}

export async function updateUserFields(
  dateOfBirth,
  firstName,
  lastName,
  gender,
  phone,
) {
  const userDoc = firebase
    .firestore()
    .collection('users')
    .doc(firebase.auth().currentUser.uid);

  await userDoc.set(
    {
      dateOfBirth,
      firstName,
      lastName,
      gender,
      phone,
    },
    {merge: true},
  );
}

export async function hasUserAcceptedLatestConsent() {
  const userRef = firebase
    .firestore()
    .collection('users')
    .doc(firebase.auth().currentUser.uid);

  const userDoc = await userRef.get();

  const consentRef = firebase
    .firestore()
    .collection('consentVersion')
    .doc('consent');

  const consentDoc = await consentRef.get();

  if (!userDoc.exists) {
    return false;
  }
  if (userDoc.data().consentVersion === undefined) {
    return false;
  }
  if (consentDoc.exists && userDoc.exists) {
    if (consentDoc.data().version && userDoc.data().consentVersion) {
      if (consentDoc.data().version === userDoc.data().consentVersion) {
        return true;
      }
      return false;
    }
  }

  return false;
}

export async function updateToLatestConsent() {
  const userDoc = firebase
    .firestore()
    .collection('users')
    .doc(firebase.auth().currentUser.uid);

  const consentDoc = await firebase
    .firestore()
    .collection('consentVersion')
    .doc('consent')
    .get();

  return userDoc.set(
    {consentVersion: consentDoc.data().version},
    {merge: true},
  );
}
