/* eslint-disable react/prop-types */
import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useAuth} from '../contexts/AuthContext';

export default function GuestRoute({component: Component, ...rest}) {
  const {currentUser, isNewUser} = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!currentUser) {
          return <Component {...props} {...rest} />;
        }
        return <Redirect to="/" />;
      }}></Route>
  );
}
