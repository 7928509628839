/* eslint-disable react/no-children-prop */
import React, {useEffect} from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import Box from '@material-ui/core/Box';

export default function Terms() {
  const markdown = `# Integritetspolicy

  ## **Introduktion**
  
  Tack för att ni valt att använda Easyjacket, Sveriges smartaste,
  smidigaste och säkraste garderobsbiljett.\
  \
  Vi önskar medge att ert användande av vår applikation är så smidigt som
  möjligt, och att ert besök inte störs av problem med garderobsbiljetter.
  Därför har vi upprättat denna integritetspolicy ("Policyn") som
  tillgodoser ert behov av insikt i hur vi arbetar och hanterar
  personuppgifter.
  
  ## **Om denna policy**
  
  Denna Policy beskriver hur Easyjacket AB ("Easyjacket") behandlar era
  ("Användarens") personuppgifter. Den omfattar samtliga tjänsteutbud som
  är hänförliga till Easyjacket-applikationen ("Applikationen"). Skulle
  Easyjacket tillhandahålla en ny tjänst, så kommer det inför lansering av
  sådan tjänst meddelas huruvida en förändring av dessa
  integritetsbestämmelser är vid handen. Meddelas ingen sådan förändring,
  så är det denna Policy som gäller även för det utökade tjänsterna.
  
  **Syftet med denna integritetspolicy är att:**
  
  -   **Säkerställa att ni har insikt i vilka personuppgifter vi insamlar,
      samt ändamålet och hanteringen av insamlad data.**
  
  -   **Tydliggöra hur insamlandet av personuppgifter tillhandahåller
      Easyjacket med nödvändig information för applikationens
      funktionssätt samt vidareutvecklingen av de tjänster Easyjacket har
      som målsättning att erbjuda er som Användare.**
  
  -   **Förklara vilka rättigheter ni har som tillhandahållare av
      personuppgifter, samt**
  
  -   **Redogöra för hur vår hantering av era personuppgifter säkerställs
      genom lagring och säkerhet i förhållande till sådan lagring av data
      som sker avseende personuppgifter.**
  
  *Om ni motsätter er Easyjackets hantering av er personuppgifter och data
  hänförlig till dessa, så har ni alltid möjlighet att avstå från
  användande av applikationen.*
  
  ## **Våra skyldigheter**
  
  Easyjacket som tillhandahållare av applikationen åtar sig att i
  förhållande till er som Användare medge:
  
  -   Rätt till tillgång - rätten att begära information om samt tillgång
      till de personuppgifter som Easyjacket behandlar vid ert nyttjande
      av applikationen Easyjacket.
  
  -   Rätt till rättelse - rätten att begära att Easyjacket rättar eller
      uppdaterar era personuppgifter om de är felaktiga eller
      ofullständiga;
  
  -   Rätt till radering - rätten att begära att Easyjacket raderar era
      personuppgifter;
  
  -   Rätt till begränsning - rätten att begära att Easyjacket tillfälligt
      eller permanent upphör med behandlingen av alla eller vissa av era
      personuppgifter;
  
  -   Rätt att invända;
  
      -   rätten att, när som helst, invända mot att Easyjacket behandlar
          era personuppgifter för ett visst ändamål;
  
      -   rätten att invända mot att era personuppgifter behandlas för
          direktmarknadsföring;
  
  -   Rätt till dataportabilitet: ni har rätten att begära en kopia av era
      personuppgifter i elektroniskt format för att överföra dessa
      personuppgifter i syfte att användas i en annan parts tjänster; samt
  
  -   Rätt att inte bli föremål för automatiserat beslutsfattande: ni har
      rätten att inte bli föremål för några beslut som grundas enbart på
      automatiserat beslutsfattande, inklusive profilering, där beslut
      skulle medföra rättsliga följder för er som person eller annan
      påverkan av liknande karaktär.
  
  Önskar ni som Användare av våra tjänster göra anspråk på en eller flera
  av dessa rättigheter så meddelas detta direkt till vår
  personuppgiftsansvarige, vilken kontaktas på:
  
  -   Mail: <Adam@easyjacket.se>
  
  Meddelade om anspråk på rättighet kontrolleras löpande och svar avges
  utan dröjsmål i syfte att tillgodose ert behov av en smidig tjänst och
  hantering av ert ärende. *Skulle ni uppleva att svaret inte tillgodoser
  ert behov, så har ni alltid möjligheten att kontakta eller framföra
  klagomål till Datainspektionen (Svensk myndighet för dataskydd).*
  
  ## **Rättslig grund för hantering av personuppgifter**
  
  Easyjacket insamlar era personuppgifter som en del av ert användande av
  vår tjänst. Genom att registrera en Användare inträder ni i ett avtal
  "Användarvillkor" med Easyjacket. Insamlingen är en nödvändig grund i
  ert fullgörande av avtalet, och krävs för att ni skall kunna nyttja
  Applikationen.
  
  Användande av Applikationen möjliggörs genom tillhandahållande av
  personuppgifterna, och i brist på tillhandahållande av personuppgifterna
  träder inget avtal i kraft mellan Easyjacket och Användaren.
  
  ## **Personuppgifter som är föremål för hantering**
  
  I syfte att tillhandahålla tjänsten Easyjacket samlar vi genom
  dataformulär in nedanstående data som är hänförliga till er.
  
  -   Kön
  
  -   Födelsedatum
  
  -   Mailadress
  
  -   Mobilnummer
  
  -   Namn
  
  -   Tidpunkten för erhållande av garderobsbiljett samt tidpunkten för
      avstämning mot biljetten vid utlämning av ert avsedda
      garderobsinnehåll.
  
  Insamling av personuppgifter sker genom dataformulär i Applikationen.
  
  ## **Hantering av Personuppgifter**
  
  Easyjacket insamlar personuppgifter i syfte att:
  
  -   Utställa en personlig garderobsbiljett som via digital hantering
      upptas av vårt centrala garderobssystem.
  
  -   Utveckla Applikationen samt tillhörande tjänster
  
  -   Marknadsföra våra tjänster
  
  -   Tillhandahålla uppgifter om våra Användare i förhållande till tredje
      part.
  
  ## **Mottagare av insamlade persondata**
  
  Nedan listas den typ av mottagare som genom avtal med Easyjacket äger
  möjlighet att mottaga insamlade persondata:
  
  -   Tredje part i form av avtalspart som genom avtalet erhåller
      insamlade persondata i syfte att genom uppgifterna rikta erbjudande
      eller marknadsföring.
  
  -   Tredje part i form av myndighet som genom föreläggande äger rätten
      att begära ut insamlade persondata.
  
  -   Tredje part i form av avtalspart som genom avtalet äger rätten att
      disponera över insamlad persondata i syfte att genomföra
      marknadsundersökning eller liknande statistisk undersökning avseende
      användandet av Applikationen.
  
  ## **Tillhandahållande av personuppgifter i förhållande till tredje part**
  
  Vid nyttjande av applikationen samlar Easyjacket in den data som är
  nödvändig för att utställa en personlig biljett, därtill samlas data in
  i syfte att tillgodose det behov som tredje part kan ha i förhållande
  till våra Användare. Denna data utgörs av de uppgifter som omnämns under
  rubriken "Personuppgifter som är föremål för hantering". Tredje part får
  genom avtal med Easyjacket nyttja den information som Easyjacket genom
  Applikationen upptar i förhållande till er som Användare. Detta innebär
  att Easyjacket i avtalsrelation med tredje part har rätt att genom avtal
  överlåta de uppgifter ni tillhandahållit Easyjacket.
  
  Easyjacket värnar om er integritet, och vid överföring av informationen
  till tredje part säkerställs att inga andra än de för transaktionen
  relevanta parterna har tillgång till den information som överförs. Om ni
  inte samtycker till denna hantering av era uppgifter så hänvisar vi till
  rubriken "Våra Skyldigheter" varpå ni kan vidta åtgärd i syfte att
  radera eller begränsa viss eller all data som Easyjacket hanterar
  avseende er person.
  
  Easyjacket säkerställer att tredje part som genom avtal om överlåtande
  får tillgång till uppgifterna har uppställt en integritetspolicy enligt
  relevant lagstiftning. Easyjacket lämnar således inte ut information om
  hanteringen av denna inte kan säkerställas. Relevant lagstiftning utgörs
  av hantering i enlighet med Dataskyddsförordningen (GDPR)
  
  ## **Hantering av personuppgifter i syfte att utveckla vår tjänst**
  
  Easyjacket använder era personuppgifter i syfte att vidareutveckla
  Applikationen, och kan vid utformandet av nya tjänster komma att nyttja
  informationen i syfte att tillgå denna såsom material för skapandet av
  sådana tjänster. Ni som Användare har samma rättigheter enligt ovan
  rubrik "Våra Skyldigheter" att vidta åtgärd i syfte att begränsa eller
  radera de uppgifter som nyttjas av oss vid detta tillfälle.
  
  ## **Hantering av personuppgifter i marknadsföringssyfte**
  
  Easyjacket använder insamlad information i syfte att marknadsföra
  Applikationen samt övriga tjänster som utvecklas av Easyjacket.
  Informationen nyttjas på sådant vis att vi genom personuppgifterna har
  möjlighet att rikta informationen till er som Användare. Rättigheten att
  begära radering eller begränsning av sådant nyttjande återfinns under
  rubriken "Våra Skyldigheter".
  
  ## **Lagring samt säkerhet för personuppgifter**
  
  Vid tillfället för insamling av personuppgifter genomförs en digital
  översändelse av informationen till Easyjackets centrala datalagring.
  Easyjacket försäkrar härmed att den lagring som vidtas upplever den
  lagtekniska standard som krävs för att lagring av personuppgifter skall
  få ske. Således kan ni som Användare försäkra er om att de uppgifter som
  lämnas genom Applikationens formulär skyddas på ett ändamålsenligt vis.
  Med lagteknisk standard avses Dataskyddsförordningen (GDPR). Lagring av
  era personuppgifter sker under tiden för ert användande av
  Applikationen. Lagring hos Easyjacket sker högst 5 (fem) år efter er
  senaste användning av Applikationen.
  
  Rättigheten att radera era uppgifter enligt rubriken "Våra Skyldigheter"
  medges genom att ni till Easyjacket meddelar att ni inte önskar att era
  personuppgifter vidare lagras i Easyjackets datalagringscentral.
  
  ## **Kontaktinformation**
  
  Personuppgiftsansvarig\
  
  Adam Andersson\
  
  [Adam@easyjacket.se\
  
  ](mailto:Adam@easyjacket.se)
  \
  Easyjacket AB
  
  `;

  return (
    <Box sx={{p: 4}}>
      <ReactMarkdown remarkPlugins={[gfm]} children={markdown} />
    </Box>
  );
}
