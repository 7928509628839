import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import {useFormik} from 'formik';
import * as yup from 'yup';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export function VerificationCodeForm({onConfirmCode}) {
  const validationSchema = yup.object({
    verificationCode: yup
      .string()
      .matches(/^[0-9]{6}$/, 'Ange koden')
      .required('Ange koden'),
  });

  const formik = useFormik({
    initialValues: {
      verificationCode: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onConfirmCode(values.verificationCode);
      // setCodeIsSent(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{height: '100%'}}>
      <Box
        sx={{
          flexDirection: 'column',
          height: '100%',
          display: 'flex',
        }}>
        <Typography variant="h6">Ange verifieringskoden</Typography>
        <Typography variant="h7" sx={{mt: 1}}>
          Koden har skickats till dig via SMS
        </Typography>

        <TextField
          fullWidth
          id="verificationCode"
          name="verificationCode"
          label="Verifieringskod"
          margin="normal"
          autoFocus
          value={formik.values.verificationCode}
          onChange={formik.handleChange}
          error={
            formik.touched.verificationCode &&
            Boolean(formik.errors.verificationCode)
          }
          helperText={
            formik.touched.verificationCode && formik.errors.verificationCode
          }
        />
        <Typography>
          Fick du ingen kod?{' '}
          <Link href="/terms" onClick={() => console.log('welp')}>
            Skicka igen
          </Link>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            flex: 1,
          }}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            sx={{mt: 2}}>
            Bekräfta
          </Button>
        </Box>
      </Box>
    </form>
  );
}
