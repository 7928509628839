import React, {useContext, useState, useEffect} from 'react';
import {auth} from '../firebase';
import {
  checkIfNewUser,
  checkIfMissingUserData,
  hasUserAcceptedLatestConsent,
} from '../services/firestore';
import firebase from 'firebase/app';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({children}) {
  const [currentUser, setCurrentUser] = useState();
  const [userData, setUserData] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const [currentFacilityId, setCurrentFacilityId] = useState(null);
  const [storedFacility, setStoredFacility] = useState(null);
  const [currentFacility, setCurrentFacility] = useState(null);
  const [facilities, setFacilities] = useState(null);
  const [isNewUser, setIsNewUser] = useState(null);
  const [isConsentLatest, setIsConsentLatest] = useState(null);
  const [storedFacilityId, setStoredFacilityId] = useState(null);

  // const updateStoredFacilityId = (id) => {
  //   console.log(currentUser);
  //   console.log('UPDATING');
  //   if (currentUser) {
  //     const found = facilities?.find((item) => item.facilityId === id);
  //     console.log('found');
  //     console.log(found);
  //     if (found) {
  //       localStorage.setItem(
  //         'storedFacility',
  //         JSON.stringify({
  //           facilityId: id,
  //           timestamp: Date.now(),
  //         }),
  //       );
  //       setStoredFacilityId(id);
  //       return true;
  //     } else {
  //       localStorage.removeItem('storedFacility');
  //       return false;
  //     }
  //   } else {
  //     localStorage.setItem(
  //       'storedFacility',
  //       JSON.stringify({
  //         facilityId: id,
  //         timestamp: Date.now(),
  //       }),
  //     );
  //   }
  // };

  const [loading, setLoading] = useState(true);

  function logout() {
    return auth.signOut();
  }

  useEffect(() => {
    console.log('storedFacility');
    console.log(storedFacilityId);
    return () => {};
  }, [storedFacilityId, facilities]);

  useEffect(() => {
    if (storedFacilityId) {
      localStorage.setItem(
        'storedFacility',
        JSON.stringify({
          facilityId: storedFacilityId,
          timestamp: Date.now(),
        }),
      );
    }
    if (facilities && storedFacilityId) {
      const found = facilities.find(
        (item) => item.facilityId === storedFacilityId,
      );
      if (found) {
        console.log('the new facilityId');
        console.log(storedFacilityId);
        localStorage.setItem(
          'storedFacility',
          JSON.stringify({
            facilityId: storedFacilityId,
            timestamp: Date.now(),
          }),
        );
        setStoredFacility(found);
      } else {
        localStorage.removeItem('storedFacility');
        setStoredFacility(false);
      }
    } else {
      setStoredFacility(null);
    }
  }, [facilities, storedFacilityId]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setLoading(true);

      setCurrentUser(null);
      setUserData(null);
      setUserStatus(null);
      setCurrentFacilityId(null);
      setCurrentFacility(null);
      setFacilities(null);
      setIsNewUser(null);
      setIsConsentLatest(null);

      if (user) {
        const newUser = await checkIfNewUser();
        setIsNewUser(newUser);

        const isConsent = await hasUserAcceptedLatestConsent();
        setIsConsentLatest(isConsent);
      }
      setCurrentUser(user);

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    let unsubscribeUserData = null;
    let unsubscribeUserStatus = null;
    let unsubscribeFacilities = null;
    if (currentUser) {
      unsubscribeUserData = firebase
        .firestore()
        .collection('users')
        .doc(currentUser.uid)
        .onSnapshot(
          (doc) => {
            setUserData(doc.data());
          },
          (error) => {
            // prevent message from popping up when signing out
          },
        );

      unsubscribeUserStatus = firebase
        .firestore()
        .collection('userStatuses')
        .doc(currentUser.uid)
        .onSnapshot(
          (doc) => {
            setUserStatus(doc.data());
          },
          (error) => {
            // prevent message from popping up when signing out
          },
        );

      unsubscribeFacilities = firebase
        .firestore()
        .collection('facilities')
        .onSnapshot(
          (docs) => {
            if (docs.empty) {
              setFacilities([]);
            } else {
              const theFacilities = [];
              docs.forEach((doc) => {
                theFacilities.push(doc.data());
              });
              setFacilities(theFacilities);
            }
          },
          (error) => {
            // prevent message from popping up when signing out
          },
        );
    }

    return () => {
      if (unsubscribeUserData !== null) {
        return unsubscribeUserData;
      }
      if (unsubscribeUserStatus !== null) {
        return unsubscribeUserStatus;
      }
      if (unsubscribeFacilities !== null) {
        return unsubscribeFacilities;
      }
    };
  }, [currentUser]);

  useEffect(() => {
    async function check() {
      if (userData) {
        const res = await checkIfMissingUserData(userData);
        const consentLatest = await hasUserAcceptedLatestConsent();
        setIsConsentLatest(consentLatest);
        if (res) {
          setIsNewUser(true);
        } else {
          setIsNewUser(false);
        }
      }
    }
    check();
    return () => {};
  }, [userData]);

  useEffect(() => {
    if (userStatus) {
      if (userStatus.isCheckedIn === true) {
        setCurrentFacilityId(userStatus.facilityId);
      } else {
        setCurrentFacilityId(null);
      }
    } else {
      setCurrentFacilityId(null);
    }
    return () => {};
  }, [userStatus]);

  useEffect(() => {
    if (facilities && currentFacilityId) {
      setCurrentFacility(
        facilities.find((item) => item.facilityId === currentFacilityId),
      );
    } else {
      setCurrentFacility(null);
    }
  }, [facilities, currentFacilityId]);

  const value = {
    currentUser,
    userData,
    currentFacility,
    isNewUser,
    isConsentLatest,
    setStoredFacilityId,
    storedFacility,
    facilities,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
