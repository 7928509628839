/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Route, Redirect, useParams, useLocation} from 'react-router-dom';
import {useAuth} from '../contexts/AuthContext';

export default function PrivateParamRoute({component: Component, ...rest}) {
  const {currentUser, isNewUser, isConsentLatest, setStoredFacilityId} =
    useAuth();
  const [facilityId, setFacilityId] = useState();
  const location = useLocation();

  useEffect(() => {
    console.log('THEEE location');
    console.log(location);

    let theParam = location.pathname;
    theParam = theParam.split('/facility/');

    const id = theParam[1];

    if (id === undefined || id === null) {
      // check local storage
    } else {
      // save to local storage
      console.log('EXISTS');
      const obj = {
        facilityId: id,
        timestamp: Date.now(),
      };
      // localStorage.setItem('storedFacility', JSON.stringify(obj));
      setStoredFacilityId(id);
      console.log(obj);
    }
    return () => {};
  }, [location]);

  return (
    <Route
      {...rest}
      render={(props) => {
        // if currentUser AND userData OK
        if (currentUser && isNewUser && !isConsentLatest) {
          return <Redirect push to={'/completeProfile' + location.pathname} />;
        }
        if (currentUser && isNewUser && isConsentLatest) {
          return <Redirect push to={'/completeProfile' + location.pathname} />;
        }
        if (currentUser && !isNewUser && !isConsentLatest) {
          return <Redirect push to={'/updateConsent' + location.pathname} />;
        }
        if (currentUser && !isNewUser && isConsentLatest) {
          return <Component {...props} />;
        }
        return <Redirect to={'/login' + location.pathname} />;
      }}></Route>
  );
}
