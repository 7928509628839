/* eslint-disable react/no-children-prop */
import React, {useEffect} from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import Box from '@material-ui/core/Box';

export default function Terms() {
  const markdown = `# Användarvillkor

  ## **Introduktion**
  
  Tack för att ni valt att använda Easyjacket, Sveriges smartaste,
  smidigaste och säkraste garderobsbiljett. I dessa användarvillkor
  ("Villkoren") stadgas de juridiska skyldigheter samt rättigheter som en
  användare av Easyjacket AB's Applikation ("Applikationen") åtar sig
  respektive förbinder sig vid. Genom avtalet regleras samtliga åtgärder
  som Easyjacket AB ("Easyjacket") kan vidta i den mån Användaren inte
  efterlever de skyldigheter som denne åtar sig genom användandet av
  applikationen. Era rättigheter i förhållande till insamling av
  personuppgifter för Applikationens funktionssätt återfinner ni under
  dokumentet "Integritetspolicy".
  
  ## **Upprättande av användare och godkännande av användarvillkor**
  
  Under tiden för ert användande är det viktigt att är införstådd i de
  rättigheter och skyldigheter som reglerar ert användande då ni vid
  upprättandet av ett användarkonto ("Kontot") genom Applikationen
  samtycker till Villkoren och innehållet i detta avtal. Genom att ta bort
  Kontot upphör Villkoren att reglera förhållandet mellan er
  ("Användaren") och Easyjacket.
  
  ## **Användandet av registrerade uppgifter**
  
  Easyjacket äger rätten att vid registrering nyttja de avgivna
  uppgifterna i syfte att genomföra avtal om överlåtelse avseende
  uppgifterna till tredje part. I den mån Användaren önskar begränsa
  Easyjackets rättigheter i detta avseende äger Användaren rätten att
  begära sådan begränsning, alternativt radering av personuppgifter, genom
  att meddela Easyjacket om detta önskemål. För vidare information
  avseende de skyldigheter Easyjacket innehar gentemot Användaren, se
  Integritetspolicy. Avseende ansvar inför tredje parts nyttjande, se
  rubrik "Tredje Parts Rättigheter".
  
  ## **Användandet av Applikationen**
  
  Användandet av Applikationen är avsett att endast nyttjas i samband med
  frånlämnande av tillhörigheter i förhållande till en garderobsinrättning
  ("Garderoben"). Applikationen tillförser Användaren med en elektronisk
  signatur ("QR-koden") som avstäms av Garderoben. Garderoben nyttjar
  systemet i syfte att specificera vilken plats i garderoben en viss
  användares tillhörigheter placeras. Garderoben scannar QR-koden i
  samband med inlämning av tillhörigheter till Garderoben. Vid utlämning
  scannas QR-koden igen och tillhörigheterna utlämnas. Applikationen
  kräver inte betalning av Användaren för att kunna nyttjas. Easyjacket
  ansvarar inte för eventuell avgift som uppställs av tredje part i
  samband med inträde till den facilitet vari Garderoben är lokaliserad.
  Easyjacket ansvarar för era tillhörigheter under den period som
  tillhörigheterna är belägna i Garderoben. Easyjacket är inte ansvariga
  att utlämna era tillhörigheter om inte uppvisande av QR-koden sker mot
  avstämning i Garderobens system. QR-koden skall uppvisas på det sättet
  som Applikationen medger. Avbildningar av QR-koden som uppvisas utanför
  Applikationen är inte giltiga.
  
  Applikationen kan användas hos tredje part som genom samarbete med
  Easyjacket uppställt system för Applikationens nyttjande. Applikationens
  användning begränsas på så vis till de tidsutrymmen som tredje part
  tillhandahåller systemet för dennes Garderob.
  
  ## **Ansvar för tillhörigheter**
  
  Easyjacket ansvarar för Användarens tillhörigheter under tiden dessa är
  i Easyjackets besittning.
  
  Besittningen inträder när överlämnande av tillhörigheterna sker och
  Användaren mottager en unik QR-kod som representerar de inlämnade
  tillhörigheterna. Besittningen upphör när QR-koden avstäms mot
  Garderobens system, och Användaren mottager de tillhörigheter som
  motsvaras av QR-koden. Har tillhörigheterna under tiden för Easyjackets
  förvaring ("Besittning") skadats på grund av försumlighet hos Easyjacket
  eller hos denne anställd, svarar Easyjacket för ersättning som motsvarar
  skadan. Easyjacket svarar inte för sådan ersättning som avser förlust i
  näringsverksamhet.
  
  Om Användaren inte kan uppvisa tillhörigheternas motsvarande QR-kod, så
  har Easyjacket rätt att kvarhålla egendomen till dess att Användaren kan
  uppvisa erforderlig bevisning om att tillhörigheterna tillhör denne.
  Erforderlig bevisning utgörs av en från Användaren eller närstående
  person tydlig beskrivning av tillhörigheternas utformning, skick och det
  innehåll som omfattas av tillhörigheterna.
  
  ## **Tredje parts ansvar för tillhörigheter**
  
  ## 
  
  Tredje part som nyttjar Easyjacket såsom system för förvaring av
  tillhörigheter ansvarar för dessa på samma vis som om Easyjacket
  tillhandahållit Garderoben. Anspråk på ersättning för skada till följd
  av försummelse vid förvaring av tillhörigheter riktas därför av
  Användaren mot den tredje part som nyttjat system till förvaring av
  tillhörigheter. Anvisar tredje part som nyttjar systemet till förvaring
  i dennes Garderob till viss tid och plats för avhämtning av kvarglömda
  tillhörigheter så skall Användaren infinna sig vid sådant ställe under
  den anvisade tidpunkten.
  
  ## **Rätten att försälja kvarglömda tillhörigheter efter uppmaning om avhämtning**
  
  Tillhörigheter som kvarlämnats hos Easyjacket får säljas under
  förutsättning att:
  
  -   Tillfället för Applikationens nyttjande upphört. Med detta avses den
      avgränsade tidsperiod under vilken Applikationen huvudsakligen
      nyttjats såsom system till förvaring av Användarens tillhörigheter.
  
  -   Användaren har till de registrerade kontaktuppgifterna tillsänts
      rekommenderat brev om att upphämta kvarglömda tillhörigheter, och
      att underlåtenhet att upphämta kvarglömda tillhörigheter kan
      resultera i att dessa försäljs efter 3 (tre) månaders tid från
      tillfället för Applikationens nyttjande i förhållande till de
      kvarglömda tillhörigheterna.
  
  -   Tiden för upphämtning av de kvarglömda tillhörigheterna har löpt ut
      (3 månader).
  
  Försäljning av kvarglömda tillhörigheter innebär att tillhörigheternas
  värde, efter avdrag för kostnader för försäljningen, återförs till
  Användaren.
  
  Kvarglömda tillhörigheter vars värde uppenbarligen inte överstiger en
  hundradel av prisbasbeloppet (2 kap 6 -- 7 § Socialförsäkringsbalken)
  och som kvarlämnats hos Easyjacket i ett (1) år efter det att tillfället
  för Applikationens nyttjande upphört får försäljas utan anmaning till
  Användaren. Överskottet skall därefter utbetalas till Användaren. Kan
  inte Easyjacket fastställa vart Användaren kan anträffas, äger
  Easyjacket rätten att begagna sig överskottet av försäljningen.
  
  ## **Skyldighet att inte vidta åtgärd i syfte att kompromettera applikationens innehåll eller avsedda ändamål för användande.**
  
  Genom att upprätta ett användarkonto på Applikationen förbinder ni er
  att inte vidta åtgärd som syftar till att försämra Applikationens
  säkerhetsmässiga integritet. Detta involverar försök till att omvandla,
  förvränga eller komma åt innehåll bortom det innehåll som Easyjacket
  avsett att Användaren skall få tillgång till genom upprättandet av ett
  användarkonto. Vidare förbinder ni er att inte överlåta den
  användarspecifika garderobsbiljetten till tredje part. Missbruk i något
  av dessa hänseenden medför att Easyjacket äger rätten att göra anspråk
  på ersättning för den skada som uppkommer genom förfarandet. Skada kan i
  detta avseende utgöras av att Easyjacket på grund av missbruk hos
  Användaren inte äger att upprätta samarbete med part som, om inte
  missbruket skett, annars hade kommit till stånd. Skada kan vidare
  utgöras av ersättningsanspråk från Användare avseende förlorade
  tillhörigheter till följd av förvrängt eller omvandlat innehåll i
  Applikationen.
  
  ## **Applikationens innehåll**
  
  Applikationens innehåll är avsett för det ändamål som tydliggörs under
  rubriken "Användandet av Applikationen". Innehåll som annars publiceras
  av Easyjacket skall anses falla inom ramen för detta innehåll.
  Innehållet utformas av Easyjacket samt till denne anknutna
  tjänsteleverantörer och samarbetsparter. Användaren åtar sig att inte
  vidta åtgärd i syfte att extrahera (från Applikationen exportera) det
  material som återfinns i Applikationen i form av logotyper,
  marknadsföringsmaterial och liknande innehåll. Återfinns det vidare i
  Applikationen innehåll eller material som tillhör tredje part, åtar sig
  Användaren att på samma vis som avser Easyjackets material att inte
  extrahera detta.\
  \
  **Applikationens inkorporering i tredjepartsapplikationer**
  
  Applikationen kan komma att nyttjas i samarbete med leverantör av
  tredjepartsapplikation. Uppstår Applikationen i sådan
  tredjepartsapplikation så är det fortsättningsvis detta avtal som
  reglerar användandet av Applikationen. Easyjacket ansvarar inte för den
  avgift som tillhandahållaren av sådan tredjepartsapplikation eventuellt
  uppställer.
  
  ## **Ändring av avtalsinnehåll**
  
  Sker ändring i avtalsinnehållet som reglerar användandet av
  Applikationen skall detta meddelas Användaren vid tillfället för sådant
  avtals inträde. Användaren äger att vid sådant tillfälle radera sitt
  användarkonto om ändringen inte faller inom ramen för det samtycke som
  krävs för användandet.
  
  ## **Avtalstid och uppsägning**
  
  Avtalets innehåll och bundenheten mellan Easyjacket och Användaren
  kvarstår under den tidsperiod som Användaren har ett registrerat Konto
  hos Easyjacket. Användaren äger rätten att uppsäga sitt Konto genom
  Applikationen, varpå regleringen upphör att gälla mellan Easyjacket och
  Användaren.
  
  QR-koden äger giltighet från det tillfälle Användaren mottager denna
  från Garderoben i utbyte mot inlämning av tillhörigheter, och upphör vid
  det tillfället Användaren mot avstämning av QR-koden i Garderoben
  mottager sina tillhörigheter.
  
  ## **Radera användarkonto**
  
  Användare som önskar radera Kontot och dess innehåll genomför sådan
  åtgärd genom Applikationen. Önskar Användaren därutöver att begära
  radering av personuppgifter som lagrats med anledning av användandet
  skall detta meddelas Easyjacket. Meddelande om sådan begäran lämnas
  genom nyttjandet av den kontaktinformation som återfinns längst ned i
  detta avtal.
  
  ## **Tredje parts rättigheter**
  
  Tillhandahållandet av Applikationen sker genom Google Play respektive
  App Store. Easyjacket ansvarar inte för de eventuella rättigheter som
  medgivits i samband med tillhandahållandet av Applikationen genom dessa
  tjänsteleverantörer. Tredje part kan genom avtal om överlåtelse avseende
  personuppgifter äga tillgång till de registrerade uppgifter som avgivits
  i samband med registrering. Dessa Användaruppgifter kan nyttjas i
  samband med erbjudande om tjänst som inte avser Applikationen.
  Easyjacket svarar inte för dessa erbjudanden. Easyjacket ansvarar dock
  för att överföringen av personuppgifterna efter avtal om överlåtelse
  sker på ett lagtekniskt korrekt sett.
  
  ## **Fullständigt avtal**
  
  Innehållet i detta avtal, rubricerat Användarvillkor, är en fullständig
  reglering av de rättigheter och förpliktelser Användaren samt Easyjacket
  överenskommit. Inga muntliga avtal äger företräde i förhållande till
  detta innehåll, och eventuell förändring av innehållet skall
  kommuniceras till Användaren på det vis som stadgas under rubriken
  "Ändring av Avtalsinnehåll".
  
  ## **Överlåtelse**
  
  Easyjacket äger rätten att överlåta Applikationen och övrig verksamhet
  till tredje part. Vid överlåtelse följer att Användaren är bunden genom
  avtalet mot ny ägare på samma vis som om Easyjacket kvarstått som ägare.
  
  ## **Anspråk på ersättning till följd av Användarens missbruk av Applikationen**
  
  Vidtar användaren sådan åtgärd som stadgas under rubriken "Skyldighet
  att inte vidta åtgärd i syfte att kompromettera applikationens innehåll
  eller avsedda ändamål för användande" eller liknande åtgärd som syftar
  till att försämra Applikationens funktionssätt eller i annat fall avsett
  att skada Easyjacket såsom organisation äger Easyjacket rätten att
  begära ersättning upp till det belopp som ersätter den skada som
  uppkommit. Detsamma gäller om Användaren extraherat Applikationens
  innehåll. Vidtar Användaren åtgärd i förhållande till Applikationen
  vilken syftar till att nedsätta eller förhindra Applikationens
  funktionssätt äger Easyjacket rätten att kräva ersättning om SEK 10
  000,00 vid varje tillfälle som sådant intrång registrerats. Vidtar
  Användaren åtgärd som medför att väsentlig nedsättning av Applikationens
  funktion inträffar skall ersättningsbeloppet uppgå till SEK 50 000,00
  för varje enskilt tillfälle. Vidtar Användaren sådan åtgärd som medför
  att Applikationens funktionssätt och integritet väsentligen nedsätts,
  för vars åtgärdande det krävs expertiskunskap i syfte att återställa
  funktionen och integriteten skall ersättningsbeloppet uppgå till SEK 100
  000,00.
  
  **Tvist**\
  \
  Eventuell tvist i anledning av innehållet i detta avtal regleras genom
  förenklat skiljeförfarande vid Stockholms Handelskammares
  Skiljedomsinstitut.
  
  ## **Kontakta oss** 
  
  Vid eventuella frågor avseende innehållet i detta avtal se under rubrik
  "Kontaktinformation".
  
  **Kontaktinformation**\
  

  Adam Andersson\
  
  [Adam@easyjacket.se\
  
  ](mailto:Adam@easyjacket.se)
  \
  Easyjacket AB
  
`;

  return (
    <Box sx={{p: 4}}>
      <ReactMarkdown remarkPlugins={[gfm]} children={markdown} />
    </Box>
  );
}
