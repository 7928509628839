import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import * as yup from 'yup';
import {useFormik} from 'formik';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export function ConsentForm({updateConsent}) {
  const validationSchema = yup.object({
    terms: yup
      .bool()
      .oneOf([true], 'Acceptera villkoren och integritetspolicyn'),
  });

  const formik = useFormik({
    initialValues: {
      terms: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      updateConsent();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{height: '100%'}}>
      <Box
        sx={{
          flexDirection: 'column',
          height: '100%',
          display: 'flex',
        }}>
        <Typography variant="h6">
          Användaravtal och integritetspolicy uppdaterade
        </Typography>
        {/* <Typography variant="h7" sx={{mt: 1}}>
          För att förbättra er användarupplevelse har vi uppdaterat våra
        </Typography> */}

        <Box sx={{width: '100', flexDirection: 'row', mt: 0}}>
          <FormControl
            error={formik.touched.terms && Boolean(formik.errors.terms)}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.terms}
                    onChange={formik.handleChange}
                    name="terms"
                  />
                }
                label={
                  <Typography variant="body">
                    Jag har läst och accepterar EasyJackets{' '}
                    <Link href="/terms" onClick={() => console.log('welp')}>
                      Användarvillkor
                    </Link>{' '}
                    och{' '}
                    <Link href="/policy" onClick={() => console.log('welp')}>
                      Integritetspolicy
                    </Link>{' '}
                  </Typography>
                }></FormControlLabel>
              {formik.touched.terms && Boolean(formik.errors.terms) ? (
                <FormHelperText>Läs och acceptera villkoren</FormHelperText>
              ) : (
                <FormHelperText> </FormHelperText>
              )}
            </FormGroup>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            flex: 1,
          }}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            id="sign-in-button"
            sx={{mt: 2}}>
            Bekräfta
          </Button>
        </Box>
      </Box>
    </form>
  );
}
