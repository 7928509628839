/* eslint-disable react/prop-types */
import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useAuth} from '../contexts/AuthContext';

export default function CompleteProfileRoute({component: Component, ...rest}) {
  const {currentUser, isNewUser} = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        //if currentUser AND userData OK
        if (currentUser && isNewUser) {
          return <Component {...props} />;
        }
        if (currentUser && !isNewUser) {
          return <Redirect to="/" />;
        }
        return <Redirect to="/login" />;
        //if currentUser but NO userData - return completeProfile
        //return componenet
        // return currentUser ? (
        //   <Component {...props} />
        // ) : (
        //   <Redirect to="/login" />
        // );
      }}></Route>
  );
}
