/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import {Route, Redirect, useParams, useLocation} from 'react-router-dom';
import {useAuth} from '../contexts/AuthContext';

export default function PrivateRoute({component: Component, ...rest}) {
  const {currentUser, isNewUser, isConsentLatest, storedFacility} = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        // if currentUser AND userData OK
        if (currentUser && isNewUser && !isConsentLatest) {
          return <Redirect to="/completeProfile" />;
        }
        if (currentUser && isNewUser && isConsentLatest) {
          return <Redirect to="/completeProfile" />;
        }
        if (currentUser && !isNewUser && !isConsentLatest) {
          return <Redirect to="/updateConsent" />;
        }
        if (currentUser && !isNewUser && isConsentLatest) {
          return <Component {...props} />;
        }
        return <Redirect to="/login" />;
      }}></Route>
  );
}
