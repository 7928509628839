/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import firebase from 'firebase/app';
import 'yup-phone';
import {phoneParser} from '../../utils/phone';

import {PhoneNumberForm} from '../forms/PhoneNumberForm';
import {VerificationCodeForm} from '../forms/VerificationCodeForm';
import {useHistory, useParams, useLocation} from 'react-router-dom';

export default function Login() {
  const [confirmation, setConfirmation] = useState(null);
  const location = useLocation();

  useEffect(() => {
    console.log('mounting');
    setConfirmation(null);

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log(response);
          onSignInSubmit();
        },
        'expired-callback': () => {
          console.log('callback expired');
        },
      },
    );
    return () => {
      window.recaptchaVerifier.reset();
    };
  }, []);

  const onSignInSubmit = (number) => {
    const appVerifier = window.recaptchaVerifier;

    const theNbr = phoneParser(number);
    firebase
      .auth()
      .signInWithPhoneNumber(theNbr, appVerifier)
      .then((confirmation) => {
        console.log(confirmation);
        setConfirmation(confirmation);
      })
      .catch((error) => {
        console.log(error);
        window.recaptchaVerifier.reset();
      });
  };

  const confirmCode = async (code) => {
    try {
      const theConfirmation = await confirmation.confirm(code);
      console.log(theConfirmation);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
      }}>
      <Container maxWidth="sm" sx={{alignItems: 'center', mt: 2}}>
        <Card sx={{minHeight: 280, display: 'flex'}}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 3,
              pt: 0,
              flex: 1,
            }}>
            <Box sx={{flex: 1}}>
              {confirmation === null ? (
                <PhoneNumberForm onSignInSubmit={onSignInSubmit} />
              ) : (
                <VerificationCodeForm onConfirmCode={confirmCode} />
              )}
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}
