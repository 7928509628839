import firebase from 'firebase/app';
import 'firebase/functions';

export async function renewCode() {
  const defaultApp = firebase.app();
  const functionsForRegion = defaultApp.functions('europe-west1');

  const theCode = await functionsForRegion.httpsCallable('renewCode')();

  return theCode;
}
